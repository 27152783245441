<template>
  <div class="dashboard">

      <div class="header">

      </div>
      <div class="middle">
        <div class="nav">
          <el-menu

              :default-active="activeIndex"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#545c64"

              text-color="#fff"
              active-text-color="#ffd04b"
              style="height: 100%;width: 100%">

            <el-menu-item index="1" @click="navigateTo('/dashboard/dataCenter')">
               <i class="el-icon-s-data"></i>
              <span slot="title">数据中心</span>
            </el-menu-item>

            <el-menu-item index="5"  @click="navigateTo('/dashboard/user')">
              <i class="el-icon-s-custom"></i>
              <span slot="title">人员管理</span>
            </el-menu-item>

            <el-menu-item index="2" @click="navigateTo('/dashboard/goods')">
              <i class="el-icon-s-goods"></i>
              <span slot="title">产品管理</span>
            </el-menu-item>

            <el-menu-item index="3" @click="navigateTo('/dashboard/order')">
              <i class="el-icon-s-order"></i>
              <span slot="title">订单管理</span>
            </el-menu-item>





          </el-menu>

        </div>

        <div class="contain">
          <router-view></router-view>  <!-- 用于渲染子路由的组件 -->
        </div>

      </div>


  </div>





</template>


<style>

body, html, .dashboard, .middle, .contain, .nav {
  margin: 0;
  padding: 0;
}
.el-menu {
  //border-right: none !important;
  border-right: solid 1px #409EFF !important;
}

.dashboard{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fafafa;
}

.dashboard .header{
  height: 50px;
  background: #4B6EA8;
  width: 100%;
  min-height: 50px;
  margin-bottom: 23px;
  min-width: 845px;

}

.dashboard .middle{
  border: 1px solid #b9c7d3;
  width: 100%;
  display: flex;
  flex:1;
  box-sizing: border-box;


}
.dashboard .middle .nav{
  width: 150px;
  height: 100%;
  box-sizing: border-box;


}


.dashboard .middle .contain{
  flex:1;
  background: #fafafa;
  box-sizing: border-box;



}

</style>

<script>
export default {
  data(){
    return{
      activeIndex: '', // 默认活动项
    }
  },
  // 可以在这里添加更多逻辑
  methods: {
    navigateTo(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    updateActiveIndex(path) {
      if (path.startsWith('/dashboard/dataCenter')) {
        this.activeIndex = '1';
      } else if (path.startsWith('/dashboard/goods')) {
        this.activeIndex = '2';
      } else if (path.startsWith('/dashboard/order')) {
        this.activeIndex = '3';
      } else if (path.startsWith('/dashboard/report')) {
        this.activeIndex = '3';
      } else if (path.startsWith('/dashboard/user')) {
        this.activeIndex = '5';
      }
      else {
        this.activeIndex = '1'; // 默认活动项
      }
    },
  },
  mounted() {
    this.updateActiveIndex(this.$route.path);
  },
}
</script>
