<template>
  <div>

    <div class="common">
      <div class="common-header">
        <div class="serch">

        </div>


      </div>
      <div class="common-middle">

        <div class="table">

          <el-card class="box-card">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <span>返回{{total}}条</span>
              <span style="margin-left: auto;">
              <el-button type="primary" size="small" @click="downloadRequest">下载</el-button>
              </span>
            </div>



          </el-card>

          <el-divider></el-divider>

          <template >
            <div v-for="(orderItem,index) in orderItems" :key="orderItem.id">
            <el-card class="box-card">
              <div>
                <span>{{ String(index+1).padStart(3, '0') }}</span>
                <span style="margin-left: 40px">{{orderItem.id}}</span>
                <span style="margin-left: 40px">{{orderItem.poId}}</span>
                <span style="margin-left: 40px">{{orderItem.goodsCode}}</span>

              </div>
              <el-divider></el-divider>
              <el-steps :active="currentStep(orderItem)" align-center v-if="orderItem.kind === 1">
                <el-step title="喷油" :description="sprayDsc(orderItem)"></el-step>
                <el-step title="装配" :description="assembleDsc(orderItem)"></el-step>
                <el-step title="装箱" :description="encasementDsc(orderItem)"></el-step>
              </el-steps>
              <el-steps :active="currentStep(orderItem)" align-center v-if="orderItem.kind === 2">
                <el-step title="贴花" :description="appliqueDsc(orderItem)"></el-step>
                <el-step title="喷油" :description="sprayDsc(orderItem)"></el-step>
                <el-step title="装配" :description="assembleDsc(orderItem)"></el-step>
                <el-step title="装箱" :description="encasementDsc(orderItem)"></el-step>
              </el-steps>
              <el-collapse v-model="activeNames" @change="handleChange">
                <el-collapse-item :title="`返工记录|次数：${orderItem.reworkList.length}`" name="1">
                  <div v-for="rework in orderItem.reworkList" :key="rework">
                    {{ rework.time }}
                    <span style="color: red">{{getUserName(rework.emp)}}</span>
                    {{ rework.process === 1 ? '喷油' : rework.process === 2 ? '贴花' : rework.process === 3 ? '装配' : '未知工序' }}
                    返工 QC: <span style="color: red">{{ getUserName(rework.qc)}}</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-card>
            <el-divider></el-divider>
            </div>
          </template>







        </div>

      </div>
      <div class="common-foot">
        <div class="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>


export default {



  mounted() {

    this.UserDic();

    if(this.$route.params.kind === 'rework'){
      this.poId = this.$route.params.id;
      this.kind = this.$route.params.kind;

      this.fetchReworkData(this.poId);
    }

    if(this.$route.params.kind === 'inventory'){
      this.poId = this.$route.params.id;
      this.kind = this.$route.params.kind;

      this.fetchInventoryData(this.poId);
    }

    if(this.$route.params.kind === 'realInput'){
      this.poId = this.$route.params.id;
      this.kind = this.$route.params.kind;

      this.fetchRealInputData(this.poId);
    }

    if(this.$route.params.kind === 'scrap'){
      this.poId = this.$route.params.id;
      this.kind = this.$route.params.kind;

      this.fetchScrapData(this.poId);
    }




  },
  methods: {
    //装箱描述
    encasementDsc(orderItem){
      var status = '';

      var emp = this.getUserName(orderItem.encasementEmp);


      if(orderItem.encasement === null){
        return '';
      }else {
        if(orderItem.encasement === 1){
          status = '完成';
        }
        if(orderItem.encasement === 2){
          status = '返工';
        }
        if(orderItem.encasement === 3){
          status = '报废'
        }
        return orderItem.encasementTime + emp + "装箱" + status ;
      }
    },


    //装配描述
    assembleDsc(orderItem){
      var status = '';

      var emp = this.getUserName(orderItem.assembleEmp);
      var qc = this.getUserName(orderItem.assembleQc)

      if(orderItem.assemble === null){
        return '';
      }else {
        if(orderItem.assemble === 1){
          status = '完成';
        }
        if(orderItem.assemble === 2){
          status = '返工';
        }
        if(orderItem.assemble === 3){
          status = '报废'
        }
        return orderItem.assembleTime + emp + "装配" + status + "QC:" + qc ;
      }
    },

    //喷油描述
    sprayDsc(orderItem){
      var status = '';
      var emp = this.getUserName(orderItem.sprayEmp);
      var qc = this.getUserName(orderItem.sprayQc)

      if(orderItem.spray === null){
        return '';
      }else {
        if(orderItem.spray === 1){
          status = '完成';
        }
        if(orderItem.spray === 2){
          status = '返工';
        }
        if(orderItem.spray === 3){
          status = '报废'
        }

        return orderItem.sprayTime + '|' + emp + '|' + "喷油"+ '|' + status + '|' + "QC:" + qc ;
      }
    },

    //贴花描述
    appliqueDsc(orderItem){
      var status = '';

      var emp = this.getUserName(orderItem.appliqueEmp);
      var qc = this.getUserName(orderItem.appliqueQc)

      if(orderItem.applique === null){
        return '';
      }else {
        if(orderItem.applique === 1){
          status = '完成';
        }
        if(orderItem.applique === 2){
          status = '返工';
        }
        if(orderItem.applique === 3){
          status = '报废'
        }

        return orderItem.appliqueTime+'|' + emp+ '|' + "贴花"+ '|' + status + '|' + "QC:" + qc ;
      }
    },

    currentStep(orderItem) {
      if(orderItem.kind === 1){
        if (orderItem.encasement === 1) {
          return 3;
        } else if (orderItem.assemble === 1) {
          return 2;
        }  else if (orderItem.spray === 1) {
          return 1;
        } else {
          return -1; // 没有步骤完成的情况下
        }
      }else {
        if (orderItem.encasement === 1) {
          return 4;
        } else if (orderItem.assemble === 1) {
          return 3;
        } else if (orderItem.spray === 1) {
          return 2;
        } else if (orderItem.applique === 1) {
          return 1;
        } else {
          return -1; // 没有步骤完成的情况下
        }
      }

    },
    //查找返工头盔
    fetchReworkData(id) {

      var form = {
        poId: id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API + '/api/order/getOrderRework', {params: form})
          .then(response => {
            this.orderItems = response.data.data.reworkList;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

    //查找库存头盔
    fetchInventoryData(id) {

      var form = {
        poId: id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API + '/api/order/getOrderInventory', {params: form})
          .then(response => {
            this.orderItems = response.data.data.inventoryList;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

    //查找实际投入头盔
    fetchRealInputData(id) {

      var form = {
        poId: id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API + '/api/order/getOrderRealInput', {params: form})
          .then(response => {
            this.orderItems = response.data.data.realInputList;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

    //查找报废头盔
    fetchScrapData(id) {

      var form = {
        poId: id,
      }

      this.$axios.get(process.env.VUE_APP_BASE_API + '/api/order/getOrderScrap', {params: form})
          .then(response => {
            this.orderItems = response.data.data.scrapList;
            this.total = response.data.total;
          })
          .catch(error => {
            console.error('Error:', error);
          });
    },

    downloadRequest(){

      var form = {
        poId : this.poId
      };

      var url = '';

      if(this.kind == 'rework'){
        url = '/api/download/orderReworkExcel'
      }

      if(this.kind == 'inventory'){
        url = '/api/download/orderInventoryExcel'
      }

      if(this.kind == 'realInput'){
        url = '/api/download/orderRealInputExcel'
      }

      if(this.kind == 'scrap'){
        url = '/api/download/orderScrapExcel'
      }

      this.$axios.get(process.env.VUE_APP_BASE_API + url,{params:form,responseType: 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.poId}.xlsx`); // 设置下载文件名
            document.body.appendChild(link);
            link.click();

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    //查找人员字典
    UserDic(){
      this.$axios.get(process.env.VUE_APP_BASE_API + '/api/user/getUserCodeAndName')
          .then(response => {
            this.userDic = response.data.data.userList;

          })
          .catch(error => {
            console.error('Error:', error);
          });
    },
    //获取姓名
    getUserName(userCode) {
      const user = this.userDic.find(u => u.userCode === userCode);
      return user ? user.name : userCode; // 如果找不到userCode对应的name，返回userCode
    }
  },
  components: {},
  data() {
      return {
        tableData: [],
        orderItems: [],
        total:0,
        poId:'',
        kind:'',
        userDic:[],
      }
    },

}
</script>

<style scoped>
/* 样式可以根据需要添加 */

.custom-table .el-table {
  font-size: 12px; /* 调整表格字体大小 */
}

.common{
  width: 100%;
  height: 100%;

}

.common .common-header{
  width: 100%;


  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end;
  flex-direction: column;
}
.common .common-header .serch{
  background: #4B6EA8;
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center; /* 水平居中 */

}
.common .common-header .serch .select-serch{
  width: 500px;
  height: 70px;

}


.common .common-header .tow-button{
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: flex-start; /* 水平靠左排列 */
  align-items: flex-end; /* 垂直靠下排列 */
}

.common .common-header .tow-button .button{
  width: 100px;
  height: 30px;

  margin-bottom: 15px;
  margin-left: 15px;
}

.common .common-middle{
  width: 100%;
  height: 100%;

  display: flex;

  flex-direction: column;

}

.bar{
  width: 100%;
  height: 50px;
  background: #4B6EA8;
}


.common .common-middle .table{
  width: 100%;
  max-width: 1700px;
  margin-right: auto;
  margin-left: auto;

}
.common .common-foot{
  background: #4B6EA8;
  width: 100%;
  display: flex;
}

</style>
