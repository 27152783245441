<template>
  <div>
  <el-button type="primary" @click="openDialog">新增订单</el-button>


  <el-dialog :visible.sync="dialogFormVisible" title="新增订单" width="50%" @close="closeAndClean">
    <el-form ref="orderForm" :model="orderForm" label-width="80px">
      <el-form-item label="订单号">
        <el-input v-model="orderForm.poId" maxlength="15"></el-input>
      </el-form-item>
      <el-form-item label="接单日期">
        <el-date-picker
            v-model="orderForm.orderDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="出货日期">
        <el-date-picker
            v-model="orderForm.shipmentDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="地区">
        <el-input v-model="orderForm.region" maxlength="15"></el-input>
      </el-form-item>
      <el-form-item label="金额">
        <el-input v-model="orderForm.amount"  @input="handleAmountInput" ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="orderForm.remark" maxlength="100"></el-input>
      </el-form-item>

      <el-form-item label="产品列表">
        <div v-for="(product, index) in orderForm.goodsItems" :key="index" style="margin-bottom: 10px;">
          <div class="row">
            <div class="select">
              <el-select v-model="product.goods" filterable placeholder="请选择">
                <el-option
                    v-for="item in goodsList"
                    :key="item.id"
                    :label="item.goods"
                    :value="item.id">
                </el-option>
              </el-select>

            </div>
            <div class="quantity">
              <el-input-number v-model="product.goodsQuantity" :min="1" placeholder="数量" :step="1"
                               step-strictly></el-input-number>
            </div>
            <div class="delete">
              <el-button type="danger" icon="el-icon-minus" @click="removeProduct(index)"></el-button>
            </div>
          </div>

        </div>
        <el-button type="primary" icon="el-icon-plus" @click="addProduct">添加产品</el-button>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelDialog">取消</el-button>
      <el-button type="primary" @click="submitForm">提交订单</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {

  data() {
    return {
      dialogFormVisible:false,
      orderForm: {
        poId: '',
        shipmentDate: '',
        orderDate: '',
        region: '',
        amount:'',
        remark: '',
        goodsItems: [],
      },
      goodsList:[],
    };
  },
  methods: {



    //amount格式
    handleAmountInput(value) {
      // 正则表达式：允许输入最多10位整数和2位小数的金额
      const decimalRegex = /^\d{0,10}(\.\d{0,2})?$/;
      if (!decimalRegex.test(value)) {
        // 如果输入不合法，修正输入
        this.orderForm.amount = value
            .replace(/[^\d.]/g, '') // 移除非数字和小数点的字符
            .replace(/^0+(\d)/, '$1') // 移除前导0
            .replace(/(\..*)\./g, '$1') // 防止多次输入小数点
            .replace(/(\.\d{2})\d+/, '$1') // 限制小数点后两位
            .replace(/^(\d{10})\d+/, '$1'); // 限制整数部分为10位
      }
    },

    closeAndClean() {
      this.orderForm = {
        poId: '',
        shipmentDate: '',
        orderDate: '',
        region: '',
        amount:'',
        remark: '',
        goodsItems: [],
      };
      this.dialogFormVisible = false;
    },
    cancelDialog(){
      this.dialogFormVisible = false;

      this.orderForm = {
        poId: '',
        shipmentDate: '',
        orderDate: '',
        region: '',
        amount:'',
        remark: '',
        goodsItems: [],
      };

    },
    openDialog(){
      this.dialogFormVisible = true


      axios.get(process.env.VUE_APP_BASE_API+'/api/order/findAllGoods')
          .then(response => {
            this.goodsList = response.data.data.goodsList;

          })
          .catch(error => {
            console.error('请求失败:', error);
          });

    },
    addProduct() {
      this.orderForm.goodsItems.push({ goods: '',poId:this.orderForm.poId, goodsQuantity: 1 });
    },
    removeProduct(index) {
      this.orderForm.goodsItems.splice(index, 1);
    },

    submitForm() {



      if(this.orderForm.poId.trim() === '' ){
        this.$message.error("请正确填写订单号");
        return;
      }
      if(this.orderForm.goodsItems.length <= 0){
        this.$message.error("请正确添加产品");
        return;
      }

      if(!this.orderForm.orderDate){
        this.$message.error("请正确填写接单日期");
        return;
      }

      if(!this.orderForm.shipmentDate){
        this.$message.error("请正确填写出货日期");
        return;
      }

      if (new Date(this.orderForm.orderDate) > new Date(this.orderForm.shipmentDate)) {
        this.$message.error('接单日期不能晚于出货日期');
        return;
      }




      var moreGoodsItems = [];
      for(const goodsItem of this.orderForm.goodsItems){
          if(goodsItem.goods === ''){
            this.$message.error("产品编码不能为空");
            return;
          }
          if(goodsItem.goodsQuantity <= 0 || goodsItem.goodsQuantity > 9999){
            this.$message.error("产品数量超过最大值9999");
            return;
          }

          if(moreGoodsItems.includes(goodsItem.goods)){
            this.$message.error("重复添加了产品")
            return;
          }else {
            moreGoodsItems.push(goodsItem.goods);
          }
      }


      axios.post(process.env.VUE_APP_BASE_API+'/api/order/add', this.orderForm)
          .then(response => {

            if(response.data.success){
              this.dialogFormVisible = false
              this.orderForm ={
                    poId: '',
                    shipmentDate: '',
                    orderDate: '',
                    region: '',
                    amount:'',
                    remark: '',
                    goodsItems: [],
              };

              this.$emit("addOrder",true);
            }else {


              this.$emit("addOrder",false);
            }
          })
          .catch(error => {
            console.error('请求失败:', error);
            this.$emit("addOrder",false);
          });
    }
  }
};
</script>

<style scoped>
/* 子组件的样式 */

.row{
  width: 100%;
  display: flex;
}
.select{
  flex: 1;
  height: 50px;
}
.quantity{
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: center;
}
.delete{
  flex: 1;
  height: 50px;
}
</style>
